export const environment = {
    production: false,
    isAdmin: false,
    adminUrl:"dev.admin.alda.avnet.com",
    apiURL:"https://dev.api.alda.avnet.com/api/",
    clientId: '34e07332-ea52-4996-8705-011c00b64171',
    tenantId: '8820279b-c0be-4bde-83ed-fe6b6f9724d5',
    authority: 'https://login.microsoftonline.com/8820279b-c0be-4bde-83ed-fe6b6f9724d5',
    redirectUri: 'https://dev.admin.alda.avnet.com/login/callback',
    postLogoutRedirectUri: 'https://dev.admin.alda.avnet.com/',
    grapAPI: 'https://graph.microsoft.com/v1.0/me',
    apiDomain: 'https://dev.api.alda.avnet.com/',
    scopes: 'api://f6ea632a-68bb-459e-9249-081b3b767ec9/Logistic-api-access',
    perPageOptions: [5, 10, 20, 30, 50, 100],
    pageSize: 10,
    reSendOTPTimeInSecond:60,
    adminAccessGroupName:"avt-amer-npr-logistic-deskless-app-admin"
};