import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { RestAPIService } from 'src/app/services/restapi.service';
import { Utils } from 'src/app/Utils';
import { FeatureAccessViewModel } from '../modules/admin/role-management/models/role.model';
import { FeatureEnum, GroupListDrpViewModel, PermissionEnum } from '../models/common.model';
import { NavigationEnd, Router } from '@angular/router';
import { TreeviewItem } from 'ngx-treeview';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
import { BroadcastService } from './BroadcastService ';
import { BehaviorSubject, Observable } from 'rxjs';

const TOKEN_KEY = 'auth-token';
const Role_KEY = 'auth-role';
const USER_KEY = 'auth-user';
const PWA_KEY = 'pwa-hide';
const LOGOUT_KEY = 'authlogout';
const SubHide_KEY = 'sub-hide';
const USERID_KEY = 'userid';
const Group_Access = 'group-access';



@Injectable({
  providedIn: 'root',
})
export class CommonService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  userId: any;
  private localDatetime: Date;
  private utcDatetime: Date;

  constructor(private _restAPI: RestAPIService, private snackBar: MatSnackBar, private route: Router,
    private authService: MsalService, private broadcastService: BroadcastService) { }



  openSnackBar(message: string, panelClass: string, duration?: number) {

    let d = (duration != null && duration > 0) ? duration : 1500;
    this.snackBar.open(message, '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: [panelClass],
      duration: d
    });
  }
  snackBarErrorMessage(message: string, duration?: number) {
    if (Utils.checkNullValue(message) != '')
      if (message == 'Oops, something went wrong!') { } else {
        this.openSnackBar(message, 'red-snackbar');
      }

  }
  snackBarErrorMessageWithTime(message: string, duration?: number) {
    if (Utils.checkNullValue(message) != '')
      if (message == 'Oops, something went wrong!') { } else {
        this.openSnackBar(message, 'red-snackbar', duration);
      }

  }

  snackBarSuccessMessage(message: string) {
    if (Utils.checkNullValue(message) != '')
      this.openSnackBar(message, 'green-snackbar');
  }
  snackBarSuccessMessageWithTime(message: string, duration: number) {
    if (Utils.checkNullValue(message) != '')
      this.openSnackBar(message, 'green-snackbar', duration);
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  setLocalStorageData(key: string, value: any) {
    localStorage.setItem(key, value);
  }
  getLocalStorageData(key: string) {
    return localStorage.getItem(key);
  }
  removeLocalStorageData(key: string) {
    return localStorage.removeItem(key);
  }
  setSessionStorageData(key: string, value: any) {
    sessionStorage.setItem(key, value);
  }
  getSessionStorageData(key: string) {
    return sessionStorage.getItem(key);
  }

  signOut(): void {
    //this.ClearLocalStorage();
    this.sendMessage();
    const sub_key = window.localStorage.getItem(SubHide_KEY);
    window.localStorage.clear();
    if (sub_key != null && sub_key != "") {
      localStorage.setItem(SubHide_KEY, sub_key != null ? sub_key : "");
    }
  }
  sendMessage() {
    this.broadcastService.sendMessage({ event: 'custom-event', data: 'logout' });
  }
  ClearLocalStorage(): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(Role_KEY);
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(PWA_KEY);
    localStorage.removeItem(USERID_KEY);

  }

  saveToken(token: string, Expirationtime: any): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);

    window.localStorage.removeItem("expires_at");
    const datevalue = new Date(Expirationtime);
    window.localStorage.setItem("expires_at", JSON.stringify(datevalue.valueOf() - 120000));
  }
  saveRoleList(rolelist: string): void {
    window.localStorage.removeItem(Role_KEY);
    window.localStorage.setItem(Role_KEY, JSON.stringify(rolelist));
  }
  getRoleList(): any {
    const user = window.localStorage.getItem(Role_KEY);
    if (user) {
      return JSON.parse(user);

    }
    return {};
  }
  clearRole() {
    window.localStorage.removeItem(Role_KEY);
  }

  getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }
  IsLoggedIn() {
    if (this.getAdminUser() != null || this.getToken() != null) {
      return true;
    } else {
      return false;
    }
  }
  saveUser(user: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }
  getUser(): any {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);

    }
    return {};
  }
  CurrentUtcDatetime() {
    const localDate = new Date(new Date());
    const utcDateString = localDate;
    return utcDateString;

  }
  ConvertDatetimetoUTC(dt: any) {
    const localDate = new Date(dt);
    const utcDateString = localDate;
    return utcDateString;

  }

  UtcToLocalDateTime(datetime: any) {
    const utcDate = new Date(datetime);
    const localTimezoneOffset = new Date().getTimezoneOffset();
    const localDatetime = new Date(utcDate.getTime() - localTimezoneOffset * 60000);
    return localDatetime;
  }

  roundMinutes(date: Date): Date {
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    date.setMinutes(0, 0, 0);
    return date;
  }

  transformToTreeview(groups: GroupListDrpViewModel[], parents: GroupListDrpViewModel[]): TreeviewItem[] {
    return parents.map(group => new TreeviewItem({
      text: group.name,
      value: group.groupId,
      checked: group.isAssigned,
      children: this.transformToTreeview(groups, groups.filter(g => g.parentGroupId === group.groupId))
    }));
  }

  getFirstLastName() {
    var objAccountInfoJSON = this.getActiveAccount();
    return objAccountInfoJSON?.name;
  }
  getActiveAccount() {
    return this.authService.instance.getActiveAccount();
  }


  getloginUserid() {
    // const requestUrl = `${environment.grapAPI}?$select=onPremisesSamAccountName`;
    const requestUrl = `${environment.grapAPI}?$select=userPrincipalName`;
    return this._restAPI.get<any>(requestUrl);
  }

  getAdminUser(): string | null {
    return window.localStorage.getItem('userid');
  }
  isAdminUser() {
    if (window.location.hostname != "" && window.location.hostname == environment.adminUrl) {
      return true;
    }
    else {
      return false;
    }
  }

  featureAccess(): Array<FeatureAccessViewModel> {
    return [{
      id: FeatureEnum.Notifications,
      name: FeatureEnum[FeatureEnum.Notifications],
      access: [
        { key: 1, value: PermissionEnum[PermissionEnum.View], checked: false },
        { key: 2, value: PermissionEnum[PermissionEnum.Create], checked: false },
        { key: 3, value: PermissionEnum[PermissionEnum.Edit], checked: false },
        { key: 4, value: PermissionEnum[PermissionEnum['Admin Edit']], checked: false }
      ]
    }, {
      id: FeatureEnum['Content Approval'],
      name: FeatureEnum[FeatureEnum['Content Approval']],
      access: [
        { key: 5, value: PermissionEnum[PermissionEnum.View], checked: false },
        { key: 0, value: '', checked: false },
        { key: 6, value: PermissionEnum[PermissionEnum.Edit], checked: false },
        { key: 7, value: PermissionEnum[PermissionEnum['Override Approval']], checked: false }
      ]
    }, {
      id: FeatureEnum['Group Management'],
      name: FeatureEnum[FeatureEnum['Group Management']],
      access: [
        { key: 8, value: PermissionEnum[PermissionEnum.View], checked: false },
        { key: 9, value: PermissionEnum[PermissionEnum.Create], checked: false },
        { key: 10, value: PermissionEnum[PermissionEnum.Edit], checked: false },
        { key: 0, value: '', checked: false }
      ]
    }, {
      id: FeatureEnum['Roles Management'],
      name: FeatureEnum[FeatureEnum['Roles Management']],
      access: [
        { key: 11, value: PermissionEnum[PermissionEnum.View], checked: false },
        { key: 12, value: PermissionEnum[PermissionEnum.Create], checked: false },
        { key: 13, value: PermissionEnum[PermissionEnum.Edit], checked: false },
        { key: 0, value: '', checked: false }
      ]
    }, {
      id: FeatureEnum['Users Management'],
      name: FeatureEnum[FeatureEnum['Users Management']],
      access: [
        { key: 14, value: PermissionEnum[PermissionEnum.View], checked: false },
        { key: 15, value: PermissionEnum[PermissionEnum.Create], checked: false },
        { key: 16, value: PermissionEnum[PermissionEnum.Edit], checked: false },
        { key: 0, value: '', checked: false }
      ]
    }];
  }

  CheckUserRole(data: any) {
    const userRoles = this.getRoleList();
    const routData = Object.values(data)
    var isauthorize = false;
    if (userRoles?.length > 0) {
      for (const v of routData) {
        if (userRoles.filter((item: any) => item.roleAccess == v).length > 0) {
          isauthorize = true;
          break
        }
      }
    }
    return isauthorize
  }
  hidePWA(): void {
    window.localStorage.removeItem(PWA_KEY);
    window.localStorage.setItem(PWA_KEY, '1');
  }
  isHidePWD() {
    if (window.localStorage.getItem(PWA_KEY) != null) {
      return true;
    }
    else {
      return false;
    }
  }
  hideSubScribeDialog(): void {
    window.localStorage.removeItem(SubHide_KEY);
    window.localStorage.setItem(SubHide_KEY, '1');
  }
  RemoveSubScribeDialogKey(): void {
    window.localStorage.removeItem(SubHide_KEY);
  }
  isHideSubScribeDialog() {
    if (window.localStorage.getItem(SubHide_KEY) != null) {
      return true;
    }
    else {
      return false;
    }
  }
  getExpiration() {
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration!);
    return new Date(expiresAt);
  }

  isTokenExpired(token?: string): boolean {
    const date = this.getExpiration();
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  checkAdminGroup() {
    var AdminGroup = environment.adminAccessGroupName;
    const requestUrl = `${environment.grapAPI}/transitiveMemberOf/microsoft.graph.group?$filter=displayName eq '${AdminGroup}'&$count=true`;
    return this._restAPI.get<any>(requestUrl);
  }
  setAdminAccessGroup(grp: any) {
    window.localStorage.removeItem(Group_Access);
    window.localStorage.setItem(Group_Access, grp);
  }

  isAdminAccess() {
    if (window.localStorage.getItem(Group_Access) != null && window.localStorage.getItem(Group_Access) == environment.adminAccessGroupName) {
      return true;
    } else {
      return false;
    }
  }


  toUTCForTimeZone(userTime: string, timezoneOffset: string): Date {
    
    // Split the time and period (AM/PM)
    const [time, period] = userTime.split(" ");
    const [hours, minutes] = time.split(":").map(Number);
  
    // Convert to 24-hour format
    const adjustedHours = period === "PM" && hours !== 12 ? hours + 12 : period === "AM" && hours === 12 ? 0 : hours;
  
    const [sign, offsetHours, offsetMinutes] = timezoneOffset.match(/([+-])(\d{2}):(\d{2})/)!.slice(1);
  
    const offsetInMinutes = (Number(offsetHours) * 60 + Number(offsetMinutes)) * (sign === "+" ? -1 : 1);
  
    // Create a date object assuming today’s date
    const localDate = new Date();
    localDate.setHours(adjustedHours, minutes, 0, 0);
  
    // Convert to UTC by adjusting the timezone offset
    return new Date(localDate.getTime() + offsetInMinutes * 60 * 1000);
  }
    
  fromUTCForTimeZone(utcDate: Date, timezoneOffset: string): string {
    const [sign, offsetHours, offsetMinutes] = timezoneOffset.match(/([+-])(\d{2}):(\d{2})/)!.slice(1);
  
    const offsetInMinutes = (Number(offsetHours) * 60 + Number(offsetMinutes)) * (sign === "+" ? -1 : 1);
  
    // Adjust UTC date to local timezone
    const localDate = new Date(utcDate.getTime() - offsetInMinutes * 60 * 1000);
  
    // Format the time to 12-hour format
    let hours = localDate.getHours();
    const minutes = localDate.getMinutes().toString().padStart(2, "0");
  
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 0 to 12 for AM times
  
    return `${hours}:${minutes} ${period}`;
  }

  gettimepickertimeformat(dt:Date)
  {

    let hours = dt.getHours();
    const minutes = dt.getMinutes().toString().padStart(2, "0");
  
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 0 to 12 for AM times
  
    return `${hours}:${minutes} ${period}`;
  }

  
  
  formatTimeTo12Hour(date: Date): string {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12;
    hours = hours ? hours : 12; // Convert 0 to 12 for midnight
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
  
    return `${hours}:${minutesStr} ${ampm}`;
  }
  
}
